import * as React from "react"
import Footer from "src/components/footer"
import Layout from "src/components/layout"
import Seo from "src/components/seo"
import ServiceHeader from 'src/components/service-header'
import Wysiwyg from "src/components/wysiwyg"
import footerData from "src/data/global/footer"

const meta = {
  title: "Datenschutz",
  description: "Du möchtest wissen, was ich zur Sicherung deiner Daten so unternehme? Dann bist du hier richtig! Willkommen in der Datenschutz-Erklärung!"
}

const atf = {
  heading: "<strong>Datenschutz</strong>",
  hasBackground: true,
  image: "services-default",
  alt: "icon of html tag",
}

const content = {
  hasBackground: false,
  heading: "<strong>ERKLÄRUNG ZUR INFORMATIONSPFLICHT </br>(DATENSCHUTZ)</strong>",
  content: [
    {
      type: "text",
      text: `
          <p>Der Schutz Ihrer persönlichen Daten ist uns ein besonderes Anliegen. Wir verarbeiten Ihre Daten daher ausschließlich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). In diesen Datenschutzinformationen informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer Website.</p>
          <p>Beim Besuch unserer Webseite wird Ihre IP-Adresse, Beginn und Ende der Sitzung für die Dauer dieser Sitzung erfasst. Dies ist technisch bedingt und stellt damit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO dar. Soweit im Folgenden nichts anderes geregelt wird, werden diese Daten von uns nicht weiterverarbeitet.</p>
          <h2><strong>Kontakt mit mir</strong></h2>
          <p>Wenn Sie per Formular auf der Website oder per E-Mail Kontakt mit uns aufnehmen, werden Ihre angegebenen Daten zwecks Bearbeitung der Anfrage und für den Fall von Anschlussfragen sechs Monate bei uns gespeichert. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.</p>
          <h2><strong>Cookies</strong></h2>
          <p>Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an.</p>
          <p>Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.</p>
          <p>Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.</p>
          <p>Bei der Deaktivierung von Cookies kann die Funktionalität unserer Website eingeschränkt sein.</p>
          <h2><strong>Ihre Rechte</strong></h2>
          <p>Ihnen stehen bezüglich Ihrer bei uns gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der uns unter hi@dominik-schratl.at oder der Datenschutzbehörde beschweren.</p>
          <h2><strong>Sie erreichen uns unter folgenden Kontaktdaten:</strong></h2>
          <p>
            <strong>Adresse:</strong></br>
            Mag. Dominik Schratl</br>
            Müllerstraße 2 / 104</br>
            6020 Innsbruck, Österreich</br>
          </p>
          <p>
            <strong>Tel:</strong> +43 677 640 875 33</br>
            <strong>E-Mail:</strong> hi@dominik-schratl.at
          </p>
      `,
    }
  ]
}

const Privacy = () => (
  <Layout>
    <Seo title={meta.title} description={meta.description} url="/datenschutz" />
    <ServiceHeader data={atf} />
    <Wysiwyg data={content} />
    <Footer data={footerData} hasBackground />
  </Layout>
)

export default Privacy
