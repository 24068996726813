import PropTypes from "prop-types"
import * as React from "react"
import ReactHtmlParser from "react-html-parser"
import { v4 as uuid } from "uuid"
import DsImage from "src/components/ds-image"

import { cWysiwygContent, cWysiwygDetailsHeading, cWysiwygSection, cWysiwygHeading, cWysiwygImg } from "./styles.module.scss"

const Wysiwyg = ({ data }) => (
  <section className={cWysiwygSection}>
    {
      data.heading && (
        <h2 className={cWysiwygHeading}>{ReactHtmlParser(data.heading)}</h2>
      )
    }
    {
      data.content.map((c) => (
        <React.Fragment key={uuid()}>
          {
            c.type === "text"
            && <div className={cWysiwygContent}>{ReactHtmlParser(c.text)}</div>
          }
          {
            c.type === "img"
            && <DsImage src={c.image} alt={c.alt} className={cWysiwygImg} />
          }
        </React.Fragment>
      ))
    }
    {
      data.details && (
        <>
          <h3 className={cWysiwygDetailsHeading}>
            <strong>Projekt-Details</strong>
          </h3>
          <ul>
            {data.details.map(detail => (
              <li key={uuid()}>{detail}</li>
            ))}
          </ul>
        </>
      )
    }
  </section>
)

Wysiwyg.defaultProps = {
  data: {
    heading: '',
    content: [],
    details: [],
  }
}

Wysiwyg.propTypes = {
  data: PropTypes.shape({
    heading: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      text: PropTypes.string,
      img: PropTypes.string,
    })),
    details: PropTypes.arrayOf(PropTypes.string),
  })
}

export default Wysiwyg
